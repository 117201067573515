<template>
    <div>
        <div class="col-md-12 flex-between-row">
            <div class="col-md-6">
                <h3 class="page-header"><i class="fa fa fa-user-md animated bounceInDown show-info"></i> View Pending
                    Orders</h3>
            </div>
            <div class="col-md-6 text-r">
                <button class="btn btn-primary" @click="refresh()"><i class="fa fa-refresh"></i> Referesh</button>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="panel panel-cascade">

                    <div class="panel-body ">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-horizontal cascde-forms">

                                    <div class="form-group flex-row CHK">
                                        <label class="col-lg-4 col-md-3 control-label">Customer Name</label>
                                        <div class="col-lg-8 col-md-9">
                                            <input class="form-control form-cascade-control input-small"
                                                v-model="customername" type="text" value="" />
                                        </div>
                                    </div>
                                    <!-- <div class="form-group flex-row CHK">
                                    <label class="col-lg-4 col-md-3 control-label">Address</label>
                                    <div class="col-lg-8 col-md-9">
                                        <input class="form-control form-cascade-control input-small" v-model="customername" type="text" value="" />
                                    </div>
                                </div> -->

                                    <div class="form-group flex-row">
                                        <label class="col-lg-4 col-md-3 control-label">Entry Date : FROM</label>
                                        <div class="col-lg-8 col-md-9">
                                            <input class="form-control form-cascade-control input-small"
                                                v-model="entrydatefrom" type="date" />
                                        </div>
                                    </div>
                                    <div class="form-group flex-row">
                                        <label class="col-lg-4 col-md-3 control-label">Dispose Date : FROM</label>
                                        <div class="col-lg-8 col-md-9">
                                            <input class="form-control form-cascade-control input-small"
                                                v-model="disposedatefrom" type="date" />
                                        </div>
                                    </div>
                                    <!-- <div class="form-group flex-row">
                                    <label class="col-lg-4 col-md-3 control-label">Schedule Date : FROM</label>
                                    <div class="col-lg-8 col-md-9">
                                        <input class="form-control form-cascade-control input-small" v-model="scheduledatefrom" type="text" />
                                    </div>
                                </div> -->
                                    <div class="form-group flex-row" style="display:none">
                                        <label class="col-lg-4 col-md-3 control-label">Status</label>
                                        <div class="col-lg-8 col-md-9">
                                            <select class="form-control form-cascade-control input-small"
                                                v-model="isdispose">
                                                <option value="0">SELECT</option>
                                                <option value="0">PENDING</option>
                                                <option value="1">DISPOSE</option>
                                            </select>
                                        </div>
                                    </div>
                                    <!-- <div class="form-group flex-row" v-if="loggedinuser?.role<2">
                                    <label class="col-lg-4 col-md-3 control-label">Followed By 12</label>
                                    <div class="col-lg-8 col-md-9">
                                        <select v-model="followbyid" class="form-control form-cascade-control input-small">
                                            <option v-for="filter in employees.filter(property => property.id ==loggedinuser.id )" :key="filter.id" :value="filter.id" ><span >{{filter.name}}</span></option>
                                        </select>             
                                    </div>
                                </div> -->
                                    <div class="form-group flex-row">
                                        <label class="col-lg-4 col-md-3 control-label">Order No.</label>
                                        <div class="col-lg-8 col-md-9">
                                            <input class="form-control form-cascade-control input-small"
                                                v-model="complaintno" type="number" />
                                        </div>
                                    </div>
                                    <div class="form-group flex-row">
                                        <label class="col-lg-4 col-md-3 control-label">Approved By</label>
                                        <div class="col-lg-8 col-md-9">
                                            <select v-model="approvedbyid"
                                                class="form-control form-cascade-control input-small">
                                                <option v-for="filter in employees" :key="filter.id" :value="filter.id">
                                                    {{ filter.name }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="form-group flex-row">
                                        <label class="col-lg-4 col-md-3 control-label">Delivered By</label>
                                        <div class="col-lg-8 col-md-9">
                                            <select v-model="deliveredbyid"
                                                class="form-control form-cascade-control input-small">
                                                <option v-for="filter in employees" :key="filter.id" :value="filter.id">
                                                    {{ filter.name }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 CHK">
                                <div class="form-horizontal cascde-forms">
                                    <div class="form-group flex-row CHK">
                                        <label class="col-lg-4 col-md-3 control-label">Customer Mobile</label>
                                        <div class="col-lg-8 col-md-9">
                                            <input class="form-control form-cascade-control input-small"
                                                v-model="customermobile" type="number" />
                                        </div>
                                    </div>

                                    <div class="form-group flex-row">
                                        <label class="col-lg-4 col-md-3 control-label">Entry Date : TO</label>
                                        <div class="col-lg-8 col-md-9">
                                            <input class="form-control form-cascade-control input-small"
                                                v-model="entrydateto" type="date" />
                                        </div>
                                    </div>
                                    <div class="form-group flex-row">
                                        <label class="col-lg-4 col-md-3 control-label">Dispose Date : TO</label>
                                        <div class="col-lg-8 col-md-9">
                                            <input class="form-control form-cascade-control input-small"
                                                v-model="disposedateto" type="date" />
                                        </div>
                                    </div>
                                    <!-- <div class="form-group flex-row">
                                    <label class="col-lg-4 col-md-3 control-label">Schedule Date : TO</label>
                                    <div class="col-lg-8 col-md-9">
                                        <input class="form-control form-cascade-control input-small" v-model="scheduledateto" type="text" value="" />
                                    </div>
                                </div> -->

                                    <div class="form-group flex-row">
                                        <label class="col-lg-4 col-md-3 control-label">Order By</label>
                                        <div class="col-lg-8 col-md-9">
                                            <select v-model="entrybyid"
                                                class="form-control form-cascade-control input-small">
                                                <option v-for="filter in employees" :key="filter.id" :value="filter.id">
                                                    {{ filter.name }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="form-group flex-row">
                                        <label class="col-lg-4 col-md-3 control-label">Status</label>
                                        <div class="col-lg-8 col-md-9">
                                            <select v-model="status"
                                                class="form-control form-cascade-control input-small">
                                                <option value="0">Pending Orders</option>
                                                <option value="1">Approved Orders</option>
                                                <option value="2">Rejected Orders</option>
                                                <option value="3">Order Dispatch</option>
                                                <option value="4">On the Way</option>
                                                <option value="5">Order Delivered</option>
                                                <option value="6">Order Rejected</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group flex-row">
                                <div class="col-md-9" style="">
                                    <input type="submit" @click="search()" value="Search"
                                        class="btn bg-primary text-white btn-md">
                                </div>
                                <div class="col-md-3" style="">
                                    <input type="submit" @click="initial()" value="Clear"
                                        class="btn bg-danger text-white btn-md">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-3">
                    <div class="total-div">
                        <label>Total Orders :</label>
                        <span class="order-val" style="background: blueviolet;">{{ this.count }}</span>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="total-div">
                        <label>Approve Orders :</label>
                        <span class="order-val" style="background: dodgerblue;">{{ this.approve }}</span>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class=" total-div">
                        <label>Dispatch Orders :</label>
                        <span class="order-val" style="background: #dc913a;">{{ this.dispatch }}</span>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="total-div">
                        <label>On The Way Orders :</label>
                        <span class="order-val" style="background: #769d28;">{{ this.onway }}</span>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class=" total-div">
                        <label>Delivered Orders :</label>
                        <span class="order-val" style="background: green;">{{ this.delivered }}</span>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class=" total-div">
                        <label>Rejected Orders :</label>
                        <span class="order-val" style="background: crimson;">{{ this.reject }}</span>
                    </div>
                </div>

            </div>
        </div>
        <div class="table-responsive">
            <div class="panel">
                <div class="panel-body" style="overflow-x:auto;padding-left:0px">
                    <table class="table users-table table-condensed table-hover table-bordered table-sm">
                        <thead class="table-font" style="font-size:13px;">
                            <tr>
                                <th>#</th>
                                <th>Order By<br>Order Date</th>
                                <th>Sale By</th>
                                <th>Vsdigi ID</th>
                                <th class="visible-lg">NAME</th>
                                <th>MOBILE</th>
                                <th>ADDRESS</th>
                                <th>Order No.</th>
                                <th>Order Date</th>
                                <th>Product</th>
                                <th>Order Remark</th>
                                <th>Dispatch Remark</th>
                                <!-- <th>DATE OF PURCHASE</th> -->
                                <!-- <th>SCHEDULED DATE</th> -->
                                <th>Approve by</th>
                                <th>Dispatched to</th>
                                <th>On The Way</th>
                                <th>Delivered /Reject Status</th>
                                <th>Order Status</th>
                                <th>ACTION</th>
                                <!-- <th>FALLOW BY</th> -->
                            </tr>
                        </thead>
                        <pending-order-row v-for="(item, index) in leads" :key="item.id" :item="item"
                            :index="index"></pending-order-row>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Constants from '../../components/utilities/Constants.vue';
import PendingOrderRow from './PendingOrderRow.vue'
export default {
    mixins: [Constants],
    components: { PendingOrderRow },
    data() {
        return {
            // leads:[],
            customername: '',
            customermobile: '',
            followbyid: 0,
            approvedbyid: 0,
            deliveredbyid: 0,
            entrydatefrom: '',
            disposedatefrom: '',
            requesttype: 4,
            scheduledatefrom: '',
            entrybyid: 0,
            disposedateto: '',
            entrydateto: '',
            scheduledateto: '',
            leadtype: 0,
            isdispose: 0,
            complaintno: '',
            status: '',
            count: 0,
            delivered: 0,
            pending: 0,
            dispatch: 0,
            onway: 0,
            reject: 0,
            approve: 0
        }
    },
    mounted() {
        this.refresh();
    },
    computed: {
        ...mapGetters(['employees', 'leads', 'loggedinuser'])
    },
    methods: {
        refresh() {
            this.initial()
            this.$store.dispatch("fetchemployees")
            this.$store.dispatch("fetchattributes")

            this.$store.commit('assignloadingstatus', 1)
            setTimeout(() => {
                let param = { isdispose: 0, requesttype: 4, userid: this.loggedinuser.id }
                if (this.loginusercheck(182)) {
                    param = { isdispose: 0, requesttype: 4, userid: 0 }
                }
                this.$http.post('api/leads/fetch/orders', param)
                    .then((response) => this.processLeadResponse(response.data))
                    .catch((err) => { console.log('', err) });
                this.$http.post('api/fetch/order/count', param)
                    .then((response) => this.processResponseCount(response.data))
                    .catch((error) => { console.log(error) });
                // this.$http.post('api/order/pending',param)
                //     .then((response)=>this.processPendingCount(response.data))
                //     .catch((error)=>{console.log(error)});
                this.$http.post('api/order/dispatch', param)
                    .then((response) => this.processDispatchCount(response.data))
                    .catch((error) => { console.log(error) });
                this.$http.post('api/order/onway', param)
                    .then((response) => this.processOnWayCount(response.data))
                    .catch((error) => { console.log(error) });
                this.$http.post('api/order/reject', param)
                    .then((response) => this.processRejectCount(response.data))
                    .catch((error) => { console.log(error) });
                this.$http.post('api/order/approve', param)
                    .then((response) => this.processApproveCount(response.data))
                    .catch((error) => { console.log(error) });
            }, 1000);
        },
        processResponseCount(data) {
            this.count = data.count;
            this.delivered = data.delivered;
        },
        processPendingCount(data) {
            this.pending = data
        },
        processDispatchCount(data) {
            this.dispatch = data
        },
        processOnWayCount(data) {
            this.onway = data
        },
        processRejectCount(data) {
            this.reject = data
        },
        processApproveCount(data) {
            this.approve = data
        },
        search() {
            let param = {
                isdispose: 0,
                customername: this.customername,
                customermobile: this.customermobile,
                followbyid: this.followbyid,
                entrydatefrom: this.entrydatefrom,
                disposedatefrom: this.disposedatefrom,
                requesttype: 4,
                scheduledatefrom: this.scheduledatefrom,
                entrybyid: this.entrybyid,
                approvedbyid: this.approvedbyid,
                disposedateto: this.disposedateto,
                entrydateto: this.entrydateto,
                scheduledateto: this.scheduledateto,
                complaintno: this.complaintno,
                leadtype: this.leadtype, status: this.status,
                deliveredbyid: this.deliveredbyid,
            }
            this.$store.commit('assignloadingstatus', 1)
            this.$http.post('api/leads/fetchrecords', param)
                .then((response) => this.processLeadResponse(response.data))
                .catch((err) => {
                    console.log('', err)
                });
            this.$http.post('api/fetch/order/count', param)
                .then((response) => this.processResponseCount(response.data))
                .catch((error) => { console.log(error) });
            // this.$http.post('api/order/pending',param)
            //     .then((response)=>this.processPendingCount(response.data))
            //     .catch((error)=>{console.log(error)});
            this.$http.post('api/order/dispatch', param)
                .then((response) => this.processDispatchCount(response.data))
                .catch((error) => { console.log(error) });
            this.$http.post('api/order/onway', param)
                .then((response) => this.processOnWayCount(response.data))
                .catch((error) => { console.log(error) });
            this.$http.post('api/order/reject', param)
                .then((response) => this.processRejectCount(response.data))
                .catch((error) => { console.log(error) });
            this.$http.post('api/order/approve', param)
                .then((response) => this.processApproveCount(response.data))
                .catch((error) => { console.log(error) });
        },
        processLeadResponse(data) {
            this.$store.commit('assignloadingstatus', 0)
            if (data.lead == null) {
                this.$store.commit("assignleads", data)
            } else {
                this.$store.commit("assignleads", data.lead)
                this.count = data.count
            }
        },
        initial() {
            this.customername = ''
            this.customermobile = ''
            this.followbyid = 0,
                this.entrydatefrom = ''
            this.disposedatefrom = ''
            this.scheduledatefrom = ''
            this.entrybyid = 0
            this.disposedateto = ''
            this.entrydateto = ''
            this.scheduledateto = ''
            this.leadtype = 0
            this.isdispose = 0
            this.complaintno = ''
            this.status = ''
            this.approvedbyid = 0
            this.deliveredbyid = 0
        }
    },
}
</script>
<style>
.flex-row {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    flex-wrap: wrap;
}

.order-val {
    padding: 2px 6px;
    border-radius: 7px;
    margin-left: 6px;
    color: #fff;
    font-weight: bold;
}
</style>